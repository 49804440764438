import { defineNuxtPlugin } from '#app/nuxt'
import { LazyToolbarSignIn, LazyContainer, LazyPageDescription, LazyPageTitle, LazyPanelActivity, LazyPanelLanguage, LazyPerPageSelect, LazySimpleMap, LazySimpleMapMarker, LazySubmitButton, LazySubsidebarDashboard, LazyThemeToggle, LazyToolbarAccountMenu, LazyToolbarActivity, LazyToolbarLanguage, LazyToolbarNotifications, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ToolbarSignIn", LazyToolbarSignIn],
["Container", LazyContainer],
["PageDescription", LazyPageDescription],
["PageTitle", LazyPageTitle],
["PanelActivity", LazyPanelActivity],
["PanelLanguage", LazyPanelLanguage],
["PerPageSelect", LazyPerPageSelect],
["SimpleMap", LazySimpleMap],
["SimpleMapMarker", LazySimpleMapMarker],
["SubmitButton", LazySubmitButton],
["SubsidebarDashboard", LazySubsidebarDashboard],
["ThemeToggle", LazyThemeToggle],
["ToolbarAccountMenu", LazyToolbarAccountMenu],
["ToolbarActivity", LazyToolbarActivity],
["ToolbarLanguage", LazyToolbarLanguage],
["ToolbarNotifications", LazyToolbarNotifications],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
