export const useDefaultHeaders = () => {
  const { selectedOrganization } = storeToRefs(useCurrentOrganizationStore())

  const { tenant } = useTenant()

  return computed(() => {
    const headers: Record<string, string | number> = {
      'X-Tenant': tenant.value,
    }

    if (selectedOrganization.value) {
      headers['Current-Organization-Id'] = selectedOrganization.value?.id as string
    }

    return headers
  })
}
