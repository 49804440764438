import revive_payload_client_lsGUfp08sS from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_vkHfpd50f1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Qvj7jHmmt0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_A0N1Qq9FdX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_meUhwIPnIi from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_InwAlWGR0D from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TWJ4wUDF0D from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_iQXqUbbvIQ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.24.3_typescript@5.4.5_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/stagedoos/.nuxt/components.plugin.mjs";
import prefetch_client_LXIECJNOTe from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._4mizlk7orb7waec5on22mitfai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_0Jo45oZoCc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_L2q8afCq3D from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_client_1Xrh5HMXIo from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.6_patch_hash=zfnymi2b2itc6bxqlfbjljgqim_magicast@0.3.5_rollup@4.24.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_UDudyDRacm from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.24.3_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_u3EKP9pu7N from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.24.3_typesc_wopxc37nungidsfhx7mixqxnwm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import default_locale_U9KBtXyBIy from "/vercel/path0/.app/plugins/default-locale.ts";
import dompurify_CKiVSAlvqt from "/vercel/path0/.app/plugins/dompurify.ts";
import yup_1KuivxImeD from "/vercel/path0/.app/plugins/yup.ts";
import vue3_signature_nftuGzYaMt from "/vercel/path0/stagedoos/plugins/vue3-signature.ts";
export default [
  revive_payload_client_lsGUfp08sS,
  unhead_vkHfpd50f1,
  router_Qvj7jHmmt0,
  payload_client_A0N1Qq9FdX,
  navigation_repaint_client_meUhwIPnIi,
  check_outdated_build_client_InwAlWGR0D,
  chunk_reload_client_TWJ4wUDF0D,
  plugin_vue3_iQXqUbbvIQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LXIECJNOTe,
  plugin_client_0Jo45oZoCc,
  plugin_L2q8afCq3D,
  plugin_client_1Xrh5HMXIo,
  i18n_UDudyDRacm,
  plugin_u3EKP9pu7N,
  directives_yBPzJQtdia,
  default_locale_U9KBtXyBIy,
  dompurify_CKiVSAlvqt,
  yup_1KuivxImeD,
  vue3_signature_nftuGzYaMt
]