<script setup lang="ts">
import type { LayoutKey } from '#build/types/layouts'

const { appName } = useAppName()
const route = useRoute()

try {
  /**
   * Global head configuration
   * @see https://unhead.harlanzw.com/
   * @see https://github.com/unjs/unhead/tree/main
   */
  useHead({
    title: route.meta.title || '',
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} - ${appName.value}` : `${appName.value}`
    },
    // link: [
    //   {
    //     rel: 'icon',
    //     type: 'image/png',
    //     href: '/img/favicon.png',
    //   },
    // ],

    // See Tairo demo app for examples
    // meta: [],
  })
}
catch (error) {
  // Silence errors in tests
  if (process.env.NODE_ENV != 'test') {
    console.error(error)
  }
}

const { authStatus } = useAuth()

const useDifferentLayouts = true
const publicLayout = 'public'
const defaultLayout = publicLayout // or: 'topnav' | 'sidebar'

// Which doesn't seem to occur when using a timeout
const layout = ref<LayoutKey>(
  authStatus.value == 'SIGNED_IN' && useDifferentLayouts
    ? defaultLayout
    : publicLayout,
)

watch(authStatus, (status) => {
  if (status == 'SIGNED_IN' && useDifferentLayouts) {
    layout.value = defaultLayout
  }
  else {
    layout.value = publicLayout
  }
})

const router = useRouter()

// When a layout is defined on a page, it should take preference of any other layout
const layoutOverride = ref<LayoutKey | false | undefined>(
  route.meta.layout as LayoutKey | undefined,
)
router.afterEach((to) => {
  if (to.meta.layout != layoutOverride.value) {
    layoutOverride.value = to.meta.layout as LayoutKey | undefined
  }
})

// See https://masteringnuxt.com/blog/nuxt-3-client-side-error-handling
// const recoverFromError = async (error: any) => {
//   await navigateTo('/')
//   error.value = null
// }

// Used to trigger full page rerenders when switches organizations
// Mainly done because most form inits aren't fully reactive.
// I guess doing a redirect would be a bit safer, since the user can still
// end up on a page that shouldn't be accessible for the current context.
const { selectedOrganization } = storeToRefs(useCurrentOrganizationStore())
</script>

<template>
  <div>
    <NuxtLayout :name="layoutOverride || layout">
      
      <NuxtLoadingIndicator color="var(--color-primary-500)" />
      
      
      <NuxtErrorBoundary>
        <NuxtPage :key="selectedOrganization?.id" />
        <template #error="{ error }">
          <TairoError
            :error="error"
            @clear-error="() => (error.value = null)"
          />
        </template>
      </NuxtErrorBoundary>
    </NuxtLayout>
  </div>
</template>
